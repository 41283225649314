.input-wrapper {
    position: relative;
    margin-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
.input-with-scanner {
    display:flex;
    justify-content: space-between;
}
.form-control {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: medium;
  line-height: 2em;
  padding-left: 5px;
  width: 100%;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
}
.form-control:focus {
  border: 1px solid #2c7ac9;
}
.control-label {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: smaller;
  padding-left: 5px;
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 1.3rem;
  color: #5d5d5d;
  transition: 0.2s ease-in-out transform;
}
.form-control:placeholder-shown + .control-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.form-control:not(:placeholder-shown) + .control-label,
.form-control:focus:not(:placeholder-shown) + .control-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-10px);
  transition: 0.2s ease-in-out transform;
}

.normal-link{
  color: #2c7ac9;
}
.normal-link:hover{
  color: #2c7ac9;
  font-weight: bold;
}

.input-error-message{
  color:red;
  font-size: small;
  margin-left: 10px;
  margin-bottom: 5px;
}

.block-message{
  font:smaller; 
  margin: 10px;
}

.checkbox-wrapper-wrapper{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.checkbox-wrapper{
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
.checkbox{
  margin-right: 5px;
}
.checkbox-label{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: small;
}
.result-channel-list{
  list-style-type: none;
}
.result-channel{
  margin-top: 10px;
}

.submit-button{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: medium;
  margin-top: 20px;
}

.result-ok{
  font-size: x-large;
  text-align: center;
  color:white;
  background-color: green;
  margin-top: 10px;
  margin-right: 5px;
}

.result-danger{
  font-size: x-large;
  text-align: center;
  color:white;
  background-color: red;
  margin-top: 10px;
  margin-right: 5px;
}

.result-warning{
  font-size: x-large;
  text-align: center;
  background-color: yellow;
  margin-top: 10px;
}

.barcode-container{
  display: flex;
  justify-content: center;
}

.icon-info{
  padding: 5px;
}

.infobox .infoboxtext {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: #fff;
  text-align: left;
  font-size: smaller;
  border-radius: 6px;
  padding: 5px;

  /* Position the infobox */
  position: absolute;
  z-index: 1;
 
}

.infobox:hover .infoboxtext {
  visibility: visible;
}

.list{
  padding: 5px;
}

.empty-list-message{
  font-size: small;
  text-align: center;
  margin-top: 40px;
}

.list-row{
  font-size: smaller;
  display: flex;
  margin-top: 10px;
  margin-left: 0;
  padding: 0;
}

.list-row-spreaded{
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}


.list-item{
  white-space: nowrap;  
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-brand{
  font-size: medium;
}

.nav-link{
  font-size: medium;
}

.button-bar{
  display: flex;
  justify-content: center;
  padding:20px;
}

.button-solitaire{
  min-width:250px;
}

.subtitle {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: medium;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  font-size: small;
  color: blue;
  background-color: #fff;
  border-color: none;
}

.nav-tabs .nav-item{
  font-size: small;
  color: gray;
  border-bottom: none;
}
a, a:hover {
  color: white
}

.viewport {
  position: relative;
}
video {
  width: 100vmin;
  object-fit: cover;
}
canvas {
  width: 100vmin;
  left: 0;
  top: 0;
  position: absolute;
}
